<template>
  <div class="hotel">
    <div class="_title" v-if="title">酒店预订</div>
    <div class="_content" :class="className">
        <div class="wrap">
          <br/> <br/>
          <div>
            <a  style='text-decoration:none; font-weight:bold' target="view_window" href="https://c.banhuiyun.com/booking/home/l18b477b6566c403/k18b7fb02d58bf8c/zh">酒店预定（成都星宸航都国际酒店）</a>
          </div>
          <br/>
          本次会议举办于四川省成都市星宸航都大酒店，同时为方便参会代表会议期间的住宿，会务组与酒店友好协商，为参会代表提供住宿预定，参会代表可自行选择是否预定。
          <br/> <br/>
          豪华标间、大床：450元/晚/间 （价格含早含税）
          <br/>
          行政标间、大床：580元/晚/间 （价格含早含税）
          <br/> <br/>
          酒店联系人： 杨辰
          <br/>
          电话：13752053275
          <br/> <br/>
          因房间数量有限，酒店住宿预定须付费锁定房间预留，预定截止日期为<b>12月1日</b>。 
          <br/>
          注：确认与费用支付等问题请联系酒店联系人，是否预定成功以及是否有空房，以酒店联系人反馈为准，不接受口头预定。如需住宿费发票，可在会后离店时前台领取。
        </div>
    </div>

       <v-dialog
      v-model="dialog"
      width="300"
    >
   

      <v-card class="saveDialog">
    

       <div class="saveDialog-desc">
         预订成功
       </div>

        <v-divider></v-divider>
          <v-btn
          class="saveDialog-btn"
            color="primary"
            text
            @click="lookOrder"
          >
           查看订单
          </v-btn>
      
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      num: 1,
      dialog:false,
      menu: false,
      menu2: false,
      rules: {
        type: [(v) => (v ? !!v.length : "请选择房间类型" || "请选择房间类型")],
        num: [(v) => v > 0 || "输入房间"],
        date: [(v) => !!v || "请选择时间"],
        staffBodyList: [
          (v) => !!v || "请输入手机",
          (v) =>
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
              v
            ) || "手机号不正确",
        ],
        call: [(v) => !!v || "请输入座机电话"],
        info: [(v) => !!v || "请输入信息"],
      },

      hotel: {
        hotelName: "三亚湾红树林度假世界",
        type: [],
        contactName:'',
        contactPhone:'',
        standardSingleNum: "1",
        standardDoubleNum: "1",
        bigbedSingleNum: "1",
        bigbedDoubleNum: "1",
        staffBodyList: [
        
        ],
        beginTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endTime: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
    };
  },
  watch: {
    staffBodyList(val) {
      this.hotel.staffBodyList = [];
      this.num = 1;

      if (val.type.length > 0) {
        val.type.map((item) => {
          if (item == "standardSingle") {
            this.traversal("号标准间(含单早)", val.standardSingleNum);
          }
          if (item == "standardDouble") {
            this.traversal("号标准间(含双早)", val.standardDoubleNum);
          }
          if (item == "bigbedSingle") {
            this.traversal("号大床房(含单早)", val.bigbedSingleNum);
          }
          if (item == "bigbedDouble") {
            this.traversal("号大床房(含双早)", val.bigbedDoubleNum);
          }
        });
      }
    },
  },
  computed: {
    staffBodyList: function () {
      return {
        type: this.hotel.type,
        standardSingleNum: this.hotel.standardSingleNum,

        standardDoubleNum: this.hotel.standardDoubleNum,

        bigbedSingleNum: this.hotel.bigbedSingleNum,

        bigbedDoubleNum: this.hotel.bigbedDoubleNum,
      };
    },
  },
  created(){
    this.hotel.type.push('standardSingle')
  },
  methods: {
    show: function (str) {
      return this.hotel.type.indexOf(str);
    },
    userAction() {
      if(!this.$refs.form.validate()){
        return
      }
      let order = JSON.parse(JSON.stringify(this.hotel));
      
      order.standardSingleNum = this.show('standardSingle')!='-1' ? order.standardSingleNum : 0;
      order.standardDoubleNum = this.show('standardDouble')!='-1' ? order.standardDoubleNum : 0;
      order.bigbedSingleNum = this.show('bigbedSingle')!='-1' ? order.bigbedSingleNum : 0;
      order.bigbedDoubleNum = this.show('bigbedDouble')!='-1' ? order.bigbedDoubleNum : 0;

      order.beginTime=new Date(order.beginTime).getTime()
      order.endTime=new Date(order.endTime).getTime()
      this.$request({
        url: "/hotel/save",
        method: "post",
        data: order,
      })
        .then(() => {
       this.dialog=true
          
        })
        
    },
    lookOrder(){
      this.dialog=false
 this.$router.push({ path: "/user", query: { num: 3 } });
    },
    addPeople(i) {
      if (this.hotel.staffBodyList[i].peopleList.length < 2) {
        this.hotel.staffBodyList[i].peopleList.push({
          name: "",
          idCard: "",
          phone: "",
        });
      }
    },
    deletePeople(i, index) {
      this.hotel.staffBodyList[index].peopleList.splice(i, 1);
    },
    allowedDates(val) {
      var now = Date.parse(new Date());
      let date = new Date(val);
      return date.getTime() + 86400000 > now;
    },
    traversal(str, num) {
      for (let i = 0; i < num; i++) {
        this.hotel.staffBodyList.push({
          roomName: this.num + str,
          peopleList: [{ name: "", idCard: "", phone: "" }],
        });
        this.num++;
      }
    },
  },

  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "tab-item",
    },
  },
};
</script>

<style lang="less" >
.saveDialog{
  
  text-align: center;
  .saveDialog-desc{
    height: 100px;
    font-size: 20px;
    line-height: 100px;

  }
  .saveDialog-btn{
    margin: 0 auto;
  }
}
.hotel {
  padding: 0 10px;
  padding-bottom: 150px;
  
  .v-menu__content {
    font-size: 12px !important;
  }
  .tips {
    font-size: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#666666, 0.3);
    margin-bottom: 20px;
    text-align: center;
  }
  .form-title {
    font-size: 20px;
    font-weight: bold;
  }
  .form-content {
    height: 50px;
  }
  input {
    text-align: center !important;
  }
  .add {
    width: 19px;
    height: 19px;
    margin-left: 10px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .delete {
    width: 22px;
    height: 21px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .short-input {
    width: 60px;
  }
  .middle-input {
    width: 100px;
  }
  .long-input {
    width: 150px;
  }
  .line {
    height: 20px;
    width: 4px;
    border-radius: 20%;
    background: #1976d2;
  }
  .RMB {
    color: red;
  }
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  ._blue {
    color: #337fe5;
  }
  .desc-title {
    color: #666666;
  }
  .bold {
    font-weight: bold;
  }
  .required {
    position: relative;

    text-align: left;
    font-weight: bold;
    &::before {
      content: "*";
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
}

.wrap{
  color: #2c3e50;
}
</style>
